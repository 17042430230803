const numFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'ZAR',
  currencyDisplay: 'narrowSymbol'
})

const getFileAsDataURL = async (newPicture) => {
  
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = async event => {
      const data = reader.result
      resolve(data)
    }
    reader.onerror = (e) => {
      console.error(e)
      reject(e)
    }
    reader.readAsDataURL(newPicture)
  })
}

const dataURItoBlob = function (dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  //New Code
  var newBlob = new Blob([ab], {type: mimeString});
  newBlob.name = "Uploaded File";
  return newBlob;
}

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

exports.formatter = numFormat
exports.getFileAsDataURL = getFileAsDataURL
exports.dataURItoBlob = dataURItoBlob
